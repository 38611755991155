import * as app from "../Services/AppService";
import PropTypes from "prop-types";

export default function Checkbox(props) {
   function handler(event) {
      const {checked} = event.target;
      if (props.OnChange && typeof props.OnChange == "function") props.OnChange(checked);
   }
   return (
      <>
         <div className={props.ContainerClass}>
            <input
               type="checkbox"
               name={props.Name}
               disabled={props.Disabled}
               className={`form-check-input ${props.Class}`}
               onChange={handler}
               checked={props.Model}
               id={props.Name}
            />
            {props.Label !== "" && (
               <label htmlFor={props.Name} className={"ms-2 " + props.LabelClass}>
                  {app.translate(props.Label)}
               </label>
            )}
         </div>
      </>
   );
}

Checkbox.propTypes = {
   OnChange: PropTypes.func.isRequired,
   Name: PropTypes.string,
   Disabled: PropTypes.bool,
   Class: PropTypes.string,
   Label: PropTypes.string,
   Model: PropTypes.bool,
};

Checkbox.defaultProps = {
   Class: "",
   LabelClass: "",
   ContainerClass: "",
   Name: "chk",
   Label: "",
   Disabled: false,
   Model: false,
};
