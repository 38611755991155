import {useState, useEffect} from "react";
import * as app from "../Services/AppService";
import {RiHospitalFill, RiMenu3Fill, RiAccountPinBoxLine} from "react-icons/ri";
import {BsFillPersonLinesFill} from "react-icons/bs";
import {FaIndustry, FaWarehouse, FaShoppingBag, FaSellsy} from "react-icons/fa";
import {IoPeopleCircleOutline} from "react-icons/io5";
import {TbReportSearch} from "react-icons/tb";
import {Link} from "react-router-dom";
import "react-tooltip/dist/react-tooltip.css";
import {Tooltip as ReactTooltip} from "react-tooltip";
export default function SideBar(props) {
   const [control, setControl] = useState({
      ActiveTab: 0,
      // Location: window.location.pathname,
   });

   const Open = props.Open;

   const sideInfo = [
      {Id: 1, Icon: <RiHospitalFill size={25} />, Name: "Hospitals", to: "/Hospitals"},
      {Id: 2, Icon: <BsFillPersonLinesFill size={25} />, Name: "Patients", to: "/Patients"},
      {Id: 3, Icon: <FaIndustry size={25} />, Name: "Suppliers", to: "/Suppliers"},
      {Id: 4, Icon: <FaWarehouse size={25} />, Name: "Warehouse", to: "/Warehouse"},
      {Id: 5, Icon: <FaShoppingBag size={25} />, Name: "Purchases", to: "/Purchases"},
      {Id: 6, Icon: <FaSellsy size={25} />, Name: "Sales", to: "/Sales"},
      {Id: 7, Icon: <RiAccountPinBoxLine size={25} />, Name: "Accounts", to: "/Accounts"},
      {Id: 8, Icon: <IoPeopleCircleOutline size={25} />, Name: "Partners", to: "/Partners"},
      {Id: 9, Icon: <TbReportSearch size={25} />, Name: "Reports", to: "/Reports"},
   ];

   useEffect(() => {
      sideInfo.map((item) => {
         if (item.to === window.location.pathname) {
            setControl((prev) => ({...prev, ActiveTab: item.Id}));
         }
      });
      if (window.location.pathname === "/") {
         setControl((prev) => ({...prev, ActiveTab: 0}));
      }
   }, [window.location.pathname]);

   const SideBarContent = (
      <div
         className={`bg-white  ${Open ? "offcanvas-body p-0" : "w-auto d-none d-sm-block"}`}
         style={{ zIndex: "1", boxShadow: "2px 0 8px"}}
      >
         <div
            style={{height: "70px"}}
            className={` align-items-center d-flex mx-2 ${Open ? "" : "justify-content-center"}`}
         >
            <RiMenu3Fill
               size={25}
               style={{cursor: "pointer"}}
               onClick={() => {
                  props.setOpen();
               }}
            />
            {Open && (
               <Link to="/" className="mx-2">
                  <img src="_Logo.svg" alt="Blood Bank Logo" style={{width: "100%"}} />
               </Link>
            )}
         </div>
         <div className={`d-flex flex-column h-75 ${Open ? "me-2 " : "text-center"}`}>
            {sideInfo.map((item, index) => {
               return (
                  <div
                     className={`my-2 position-relative tooltip_class ${
                        control.ActiveTab === item.Id ? "bg-primary rounded-3" : ""
                     }`}
                     style={{cursor: "pointer"}}
                     onClick={() => setControl((prev) => ({...prev, ActiveTab: item.Id}))}
                     key={index}
                     data-tooltip-id="tooltip"
                     data-tooltip-content={app.translate(item.Name)}
                     data-tooltip-place="bottom"
                  >
                     {!Open && <ReactTooltip id="tooltip" style={{background: "#1F5C55"}} />}
                     <Link
                        to={item.to}
                        className={`text-decoration-none d-flex py-2 px-3 ${
                           control.ActiveTab === item.Id ? "text-light " : ""
                        }`}
                     >
                        {item.Icon}
                        {Open && <span className="fw-bold mx-2">{app.translate(item.Name)}</span>}
                     </Link>
                  </div>
               );
            })}
         </div>
      </div>
   );

   return Open ? (
      <div
         className="offcanvas position-absolute top-0 vh-100 col-6 col-sm-3 col-lg-2 show sticky-lg-top"
         tabIndex="-1"
         style={{visibility: "visible"}}
         id="bdSidebar"
         aria-labelledby="bdSidebarOffcanvasLabel"
      >
         {SideBarContent}
      </div>
   ) : (
      SideBarContent
   );
}
