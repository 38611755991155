import React from "react";
import PropTypes from "prop-types";
import {FiArrowDownCircle} from "react-icons/fi";
import {BsArrowLeftCircleFill} from "react-icons/bs";
import * as app from "../../Services/AppService";
import "./Accordion.css";
function AccordionItem(props) {
   return (
      <div className={`accordion-item my-2   ${props.Class}`}>
         {props.Title && (
            <h2 className="accordion-header" id="headingOne">
               <button
                  style={{height: "35px"}}
                  className={` rounded rounded-2 ${props.BtnClass}`}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#${props.Title}`}
                  aria-expanded="true"
                  onClick={props.Clicked}
                  disabled={props.Disabled}
               >
                  {props.Id && <span className={props.Disabled ? "text-white" : "text-dark"}>{props.Id} -</span>}
                  <span className={` ${props.Disabled ? "text-white" : props.TitleClass}`}>
                     {" "}
                     {app.translate(props.Title)}
                  </span>
                  {props.BtnIcon &&
                     (props.Open ? <FiArrowDownCircle className="mx-1" /> : <BsArrowLeftCircleFill className="mx-1" />)}
               </button>
            </h2>
         )}
         <div id={props.Title} className={`accordion-collapse collapse  ${props.Show ? "show" : ""}`}>
            <div className={`accordion-body ${props.BodyClass}`}>{props.children}</div>
         </div>
      </div>
   );
}

AccordionItem.propTypes = {
   Class: PropTypes.string,
   BodyClass: PropTypes.string,
   Disabled: PropTypes.bool,
   Title: PropTypes.string,
   Clicked: PropTypes.func,
   Show: PropTypes.bool,
   TitleClass: PropTypes.string,
   BtnClass: PropTypes.string,
   BtnIcon: PropTypes.bool,
   Open: PropTypes.bool,
};

AccordionItem.defaultProps = {
   Class: " ",
   BodyClass: "bg-white py-0",
   Disabled: false,
   Show: false,
   BtnClass: "accordion-button bg-primary",
};

export default AccordionItem;
