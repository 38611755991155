import React, {useState, useEffect} from "react";
import PropTypes from "prop-types";
import * as app from "../../Services/AppService";
import "./AutoComplete.css";

function AutoComplete(props) {
   const defaultItem = {
      Key: "0",
      Value: props.DefaultValue,
   };

   const defaulModel = {
      item: defaultItem,
      searchItems: [],
      cursor: -1,
      EnterClicked: false,
      selected: false,
   };

   const [model, setModel] = useState(defaulModel);

   useEffect(() => {
      let selectedItem = props.Options.find((item) => item.Key.toString() === model.item.Key.toString());
      setModel((old) => ({
         ...defaulModel,
         item: selectedItem != undefined ? selectedItem : props.OnSearch ? old.item : defaultItem,
         searchItems: old.EnterClicked || (props.OnSearch && model.item.Value) ? props.Options : [],
      }));
   }, [props.Options]);

   function selectedItemChanged(item) {
      if (props.OnChange && typeof props.OnChange == "function") props.OnChange(item.Key, item, props.Key);
   }

   useEffect(() => {
      defaultItem.Value = props.DefaultValue;
      setModel((old) => ({...defaulModel, item: {...old.item, Value: props.DefaultValue}}));
   }, [props.DefaultValue]);

   useEffect(() => {
      let selectedItem = props.Options.find(
         (item) => props.Model && item.Key && item.Key.toString() === props.Model.toString()
      );
      if (selectedItem != undefined) {
         setModel((oldModel) => ({...oldModel, selected: true, item: selectedItem, searchItems: []}));
         selectedItemChanged(selectedItem);
      } else {
         setModel((oldModel) => ({
            ...oldModel,
            selected: true,
            item: {Key: props.Model, Value: props.DefaultValue},
            searchItems: [],
         }));
         selectedItemChanged({Key: props.Model, Value: props.DefaultValue});
      }
   }, [props.Model]);

   function onInputFocus() {
      if (model.item.Value == "") {
         autocomplete();
      }
      if (props.OnFocus && typeof props.OnFocus == "function") props.OnFocus();
   }

   function autocomplete(evt) {
      let text = evt ? evt.target.value : props.DefaultValue;
      let item = {Value: text, Key: 0};
      setModel((oldModel) => ({
         ...oldModel,
         item,
         searchItems: text.length < props.Min ? [] : props.Options.filter((r) => props.Filter(r, text)),
      }));
      if (props.OnSearch && typeof props.OnSearch == "function") props.OnSearch(text);
   }

   function close() {
      selectedItemChanged(model.selected ? model.item : defaultItem);
      setModel((oldModel) => {
         return {...oldModel, searchItems: [], item: model.selected ? oldModel.item : defaultItem};
      });
   }

   function hanldeKeydown(evt) {
      const {cursor, searchItems} = model;
      if (evt.keyCode === 38 && cursor > 0) {
         setModel((oldModel) => ({...oldModel, cursor: oldModel.cursor - 1}));
      } else if (evt.keyCode === 40 && cursor < searchItems.length - 1) {
         setModel((oldModel) => ({...oldModel, cursor: oldModel.cursor + 1}));
      } else if (evt.keyCode === 13) {
         let currentItem = searchItems[cursor];
         if (currentItem !== undefined) {
            setModel((oldModel) => ({...oldModel, item: currentItem, searchItems: []}));
            selectedItemChanged(currentItem);
         } else if (props.OnEnterClicked && typeof props.OnEnterClicked == "function") {
            props.OnEnterClicked(model.item.Value);
            setModel((old) => ({
               ...defaulModel,
               item: {...old.item, Key: "0"},
               EnterClicked: true,
               item: {...old.item, Value: ""},
            }));
         }
      }
   }

   function selectItem(id) {
      let selectedItem = props.Options.find((item) => item.Key.toString() === id.toString());
      if (selectedItem != undefined) {
         setModel((oldModel) => ({...oldModel, selected: true, item: selectedItem, searchItems: []}));
         selectedItemChanged(selectedItem);
      } else {
         setModel((oldModel) => ({...oldModel, selected: false, item: defaultItem, searchItems: []}));
         selectedItemChanged(defaultItem);
      }
   }

   function onblur() {
      if (props.OnBlur && typeof props.OnBlur == "function") {
         props.OnBlur();
      }
   }

   return (
      <div className={props.containerClass}>
         {props.Label && <label className={"fw-semibold  " + props.LabelClass}>{app.translate(props.Label)}</label>}
         <div className={"input-group  input-group-sm border rounded bg-light " + props.InputContainerClass}>
            <div className="position-relative d-flex w-100" onBlur={close}>
               <input
                  type="text"
                  key={props.Key}
                  value={model.item.Value}
                  className={`form-control form-control-sm rounded ${
                     props.Required && !model.item.Value ? " is-invalid  border-1" : " border border-0 " + props.Class
                  }`}
                  onChange={autocomplete}
                  onKeyDown={hanldeKeydown}
                  disabled={props.Disabled}
                  onFocus={onInputFocus}
                  onBlur={onblur}
                  placeholder={app.translate(props.placeholder)}
                  required={props.Required && !model.item.Value}
               />
               {
                  <ul className="position-fixed z-index-5 overflow-auto list-group shadow rounded-bottom mt-4 w-25 p-0">
                     {model.searchItems.map((item, idx) => (
                        <li
                           onClick={() => selectItem(item.Key)}
                           style={{cursor: "pointer"}}
                           onMouseDown={() => selectItem(item.Key)}
                           key={idx}
                           className={
                              model.cursor === idx
                                 ? "active list-group-item list-group-item-action p-1 rounded-0"
                                 : "list-group-item list-group-item-action list-group-item-light p-1 rounded-0"
                           }
                        >
                           <div>
                              <div className="title">{item.Value}</div>
                           </div>
                        </li>
                     ))}
                  </ul>
               }
               {props.Icon && (
                  <button className="btn btn-sm btn-light" type="button">
                     {props.Icon}
                  </button>
               )}
            </div>
         </div>
      </div>
   );
}

AutoComplete.propTypes = {
   Label: PropTypes.string,
   Options: PropTypes.array.isRequired,
   Class: PropTypes.string,
   Model: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
   Disabled: PropTypes.bool,
   Required: PropTypes.bool,
   OnChange: PropTypes.func,
   placeholder: PropTypes.string,
   Icon: PropTypes.string,
   OnEnterClicked: PropTypes.func,
   DefaultValue: PropTypes.string,
   containerClass: PropTypes.string,
   OnBlur: PropTypes.func,
   Min: PropTypes.number,
   OnSearch: PropTypes.func,
   OnFocus: PropTypes.func,
   Filter: PropTypes.func,
   InputContainerClass: PropTypes.string,
   LabelClass: PropTypes.string,
};

AutoComplete.defaultProps = {
   Class: " ",
   DefaultValue: "",
   Default: 0,
   containerClass: "mt-2",
   Disabled: false,
   Required: false,
   Min: 0,
   Filter: (r, text) => r.Value.toLowerCase().includes(text.toLowerCase()),
   InputContainerClass: "",
   LabelClass: "text-primary",
};

export default AutoComplete;
