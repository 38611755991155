import PropTypes from "prop-types";
import * as app from "../Services/AppService";

export default function Button(props) {
   return (
      <button type="button" className={"btn " + props.Class} onClick={props.Clicked} disabled={props.Disabled}>
         {props.children}
         {props.Label !== "" && (
            <span className={props.Icon !== "" ? "mx-1 " : " " + props.LabelClass}>
               {app.translate(`${props.Label}`)}
            </span>
         )}
      </button>
   );
}

Button.propTypes = {
   Clicked: PropTypes.func.isRequired,
   Class: PropTypes.string,
   Disabled: PropTypes.bool,
   Label: PropTypes.string,
   Icon: PropTypes.bool,
   LabelClass: PropTypes.string,
   Position: PropTypes.string,
};

Button.defaultProps = {
   Disabled: false,
   Label: "",
   Class: "",
   LabelClass: "",
};
