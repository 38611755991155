import React from 'react';
import PropTypes from "prop-types";

export default function Tab(props) {
    return (
        <div id={props.id}>
            {props.children}
        </div>
    );
}

Tab.propTypes = {
    id: PropTypes.string
}

