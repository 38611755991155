import React from "react";
import PropTypes from "prop-types";
import * as app from "../Services/AppService";

function Textarea(props) {
   function handler(event) {
      const {value} = event.target;
      if (props.OnChange && typeof props.OnChange == "function") props.OnChange(value, props.Key);
   }

   return (
      <div className={` ${props.MainContainerClass}`}>
         {props.Label !== "" && <label className={`${props.LabelClass}`}>{app.translate(props.Label)}</label>}
         <div className={`input-group border rounded ${props.ContainerClass}`}>
            <textarea
               type={props.Type}
               key={props.Key}
               className={`form-control form-control-sm border rounded border  ${props.Class}`}
               disabled={props.Disabled}
               onChange={handler}
               value={props.Model}
               onBlur={props.OnBlur}
               placeholder={app.translate(props.Placeholder) + app.translate(props.Symbol)}
               rows={props.Rows}
               dir={props.Dir}
            ></textarea>
         </div>
      </div>
   );
}

Textarea.propTypes = {
   Label: PropTypes.string,
   LabelFunc: PropTypes.bool,
   Class: PropTypes.string,
   LabelClass: PropTypes.string,
   Disabled: PropTypes.bool,
   Type: PropTypes.string,
   Rows: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
   Model: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
   Placeholder: PropTypes.string,
   OnChange: PropTypes.func,
   Key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
   OnBlur: PropTypes.func,
   Symbol: PropTypes.string,
   Type: PropTypes.string,
   MainContainerClass: PropTypes.string,
   Dir: PropTypes.string,
};

Textarea.defaultProps = {
   Label: "",
   Class: " border-0 ",
   LabelClass: "text-dark",
   Type: "text",
   Placeholder: "",
   ContainerClass: " ",
   Disabled: false,
   Key: "input",
   IsTable: false,
   Symbol: "",
   Type: "text",
   MainContainerClass: "",
   Dir: "rtl",
};

export default Textarea;
