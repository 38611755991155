import Routes from "./Routes";
import Spinner from "./Utils/Spinner";
import {useEffect} from "react";
import {useDispatch,useSelector} from "react-redux";
import MsgDialog from "./Utils/MsgDialog";
import ConfirmDeletion from "./Utils/ConfirmDeletion";
import { setUserInfo } from './Store/Reducers/Auth'
import { BASE_SITE_URL } from "./Utils/constants";
import { Config } from "./Features/Login/OAuthConfiguration";
function App() {
   let SpinnerState = useSelector((state) => state.Spinner);
   let data = useSelector((state) => state.DisplayApiMsg.ReturnApiData);
   let user = JSON.parse(localStorage.getItem('user'));
   const dispatch=useDispatch();
   useEffect(()=>{
      dispatch(setUserInfo(user?user:{access_token:"",UserName:"",IsLoggedIn:false}))
   },[])
   return (
      <div className="position-relative">
         <Spinner Loading={SpinnerState.show} />
        {data?.show&& <MsgDialog />}
         <ConfirmDeletion />
         <div className={SpinnerState.show ? "d-none" : ""}>
            <Routes />
         </div>
      </div>
   );
}

export default App;
