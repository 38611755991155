import {useState, useEffect} from "react";
import PropTypes from "prop-types";
import * as app from "../../Services/AppService";
import "./Table.css";
import TablePagination from "./TablePagination.js";
import Input from "../Input";

export default function ApiTable(props) {
   const [paginationModel, setPaginationModel] = useState({
      totalCount: 0,
      totalPages: 0,
      pageSize: 0,
      pageIndex: 0,
      possiblePageSizes: [
         {Key: 10, Value: 10},
         {Key: 50, Value: 50},
         {Key: 100, Value: 100},
         {Key: 1000, Value: 1000},
      ],
      pageData: [],
   });

   function PageIndexHandle(newIndex) {
      if (newIndex <= paginationModel.totalPages && newIndex > 0) {
         setPaginationModel((old) => ({...old, pageIndex: newIndex, pageData: props.Data}));
         if (props.ChoosePagenateNumber !== undefined)
            typeof (props.ChoosePagenateNumber == "function") &&
               props.ChoosePagenateNumber((newIndex - 1) * paginationModel.pageSize, paginationModel.pageSize);
      }
   }

   function PageSizeHandle(newSize) {
      setPaginationModel((old) => ({
         ...old,
         pageSize: Number(newSize),
         totalPages: paginationModel.totalCount < newSize ? 1 : Math.ceil(paginationModel.totalCount / newSize),
         pageData: props.Data,
         pageIndex: 1,
      }));
      if (props.ChoosePagenateNumber !== undefined)
         typeof (props.ChoosePagenateNumber == "function") && props.ChoosePagenateNumber(0, newSize);
   }

   useEffect(() => {
      setPaginationModel((old) => ({
         ...old,
         totalCount: props.TotalCount ? props.TotalCount : props.Data.length,
         pageData: props.Data,
      }));
   }, [props.Data, props.TotalCount]);

   useEffect(() => {
      let totalPages =
         paginationModel.totalCount < paginationModel.pageSize
            ? 1
            : Math.ceil(paginationModel.totalCount / paginationModel.pageSize);
      setPaginationModel((old) => ({
         ...old,
         totalPages,
         pageData: props.Data,
         totalCount: props.TotalCount ? props.TotalCount : props.Data.length,
         pageIndex: 1,
         pageSize: 10,
      }));
   }, [paginationModel.totalCount]);

   let rowCountElement = (
      <span className="fw-normal px-2 text-secondary">
         {app.translate("CountRecord")} :<small className="fw-bold">{paginationModel.totalCount}</small>
      </span>
   );

   return (
      <>
         <div className={props.ContainerClass && " overflow-auto " + props.ContainerClass}>
            <table
               className={`table  align-middle table-hover m-0 ${props.Class}`}
               aria-labelledby="tabelLabel"
               ref={props.tableRef}
            >
               {props.showHeader && (
                  <thead className="position-sticky">
                     <tr className={props.TheadTrClass}>
                        {props.Cols.map((col, index) => {
                           return (
                              <th
                                 key={index}
                                 scope="col-2"
                                 className={
                                    paginationModel.pageData.length > 0 &&
                                    typeof props.Rows[index] === "function" &&
                                    ((props.Rows[index](paginationModel.pageData[0]).type &&
                                       props.Rows[index](paginationModel.pageData[0]).type.name === "Button") ||
                                       (props.Rows[index](paginationModel.pageData[0]).props &&
                                          props.Rows[index](paginationModel.pageData[0])?.props?.children &&
                                          props.Rows[index](paginationModel.pageData[0])?.props?.children[0] &&
                                          props.Rows[index](paginationModel.pageData[0])?.props?.children[0]?.type &&
                                          (props.Rows[index](paginationModel.pageData[0])?.props?.children[0]?.type
                                             ?.name === "Button" ||
                                             props.Rows[index](paginationModel.pageData[0])?.props?.children[0]?.type
                                                ?.name === "DropdownButton")))
                                       ? "text-center position-sticky end-0 bg-body "
                                       : "text-center fw-bold"
                                 }
                              >
                                 {typeof col === "function" ? col(index) : app.translate(col)}
                              </th>
                           );
                        })}
                     </tr>
                  </thead>
               )}
               <tbody>
                  {paginationModel.pageData.length === 0 && (
                     <tr>
                        <td colSpan="100" className="table-active text-center fw-bold">
                           {app.translate("EmptyTable")}
                        </td>
                     </tr>
                  )}
                  {paginationModel.pageData &&
                     paginationModel.pageData.map((row, Rowindex) => (
                        <tr key={Rowindex} className={"fw-lighter rounded text-center " + props.TdClass}>
                           {props.Rows &&
                              props.Rows.map((name, Colindex) => {
                                 return typeof name === "function" && name(row).type === "td" ? (
                                    name(row)
                                 ) : (
                                    <td
                                       key={Colindex + "|" + Rowindex}
                                       className={
                                          typeof name === "function" &&
                                          ((name(row).type &&
                                             (name(row).type.name === "Button" ||
                                                name(row).type.name === "DropdownButton")) ||
                                             (name(row).props &&
                                                name(row).props?.children &&
                                                name(row).props?.children[0]?.type &&
                                                (name(row).props?.children[0]?.type?.name === "Button" ||
                                                   name(row).props?.children[0]?.type?.name === "DropdownButton")))
                                             ? "text-center position-sticky end-0 bg-body  "
                                             : "text-center fw-normal"
                                       }
                                    >
                                       {typeof name === "function" ? name(row, Rowindex, Colindex) : row[name]}
                                    </td>
                                 );
                              })}

                           {props.TableElements &&
                              props.TableElements.map((name, ind) => (
                                 <td key={ind + "|" + Rowindex} className="text-center fw-normal ">
                                    <Input
                                       Model={row[name]}
                                       Key={name}
                                       TableClass={
                                          props.ElementClass && typeof props.ElementClass === "function"
                                             ? props.ElementClass(name, row, ind)
                                             : props.ElementClass
                                       }
                                       Disabled={
                                          props.TableElementsDisable && typeof props.TableElementsDisable === "function"
                                             ? props.TableElementsDisable(name, row, ind)
                                             : props.TableElementsDisable
                                       }
                                       IsTable={true}
                                       OnChange={(value, propName) => {
                                          props.OnTableElmChanged &&
                                             props.OnTableElmChanged(row[props.Key], value, propName);
                                       }}
                                    />
                                 </td>
                              ))}
                        </tr>
                     ))}
               </tbody>
            </table>
         </div>
         {!props.HidePagination && (
            <TablePagination
               model={paginationModel}
               PageIndexHandle={PageIndexHandle}
               PageSizeHandle={PageSizeHandle}
               HideTotalCount={props.HideTotalCount}
               rowCountElement={rowCountElement}
               Count={props.Data && " " + paginationModel.totalCount}
            />
         )}
         {!props.HideTotalCount && props.HidePagination && <div className="row g-0 my-4 me-5">{rowCountElement}</div>}
      </>
   );
}

ApiTable.propTypes = {
   Cols: PropTypes.array.isRequired,
   Rows: PropTypes.array.isRequired,
   Data: PropTypes.array.isRequired,
   Key: PropTypes.string,
   Class: PropTypes.string,
   TdClass: PropTypes.string,
   TableElements: PropTypes.array,
   OnTableElmChanged: PropTypes.func,
   TheadTrClass: PropTypes.string,
   ElementClass: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
   RowClicked: PropTypes.func,
   HideTotalCount: PropTypes.bool,
   HidePagination: PropTypes.bool,
   TableElementsDisable: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
   showHeader: PropTypes.bool,
};

ApiTable.defaultProps = {
   Class: " ",
   ContainerClass: "tableWrap-md ",
   TdClass: " ",
   TheadTrClass: "text-primary bg-light rounded border border-1 ",
   ElementClass: "",
   HideTotalCount: false,
   HidePagination: false,
   TableElementsDisable: false,
   showHeader: true,
};
