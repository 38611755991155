import {createSlice} from "@reduxjs/toolkit";

const initialState = {
   show: false,
};

const reducers = {
   setSpinnerStatus(state, action) {
      state.show = action.payload;
   },
};

export const SpinnerSlice = createSlice({
   name: "Spinner",
   initialState,
   reducers: {...reducers},
});

export const {setSpinnerStatus} = SpinnerSlice.actions;

export default SpinnerSlice.reducer;
