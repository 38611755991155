import { Outlet, useNavigate } from "react-router-dom";
import NavBar from "./NavBar";
import { useEffect, useState } from "react";
import Sidebar from "./SideBar";
import * as app from "../Services/AppService";
import "./UserLayout.css";
export default function UserLayout() {
   const Navigate = useNavigate();
   useEffect(() => {
      if (!app.CheckLogin) {
         Navigate("/login");
      }
   }, []);

   const [control, setControl] = useState({
      isMenuOpen: false,
   });
   return (
      <div className="d-block d-sm-flex position-relative">
         <Sidebar
            Open={control.isMenuOpen}
            setOpen={() => {
               setControl((prev) => ({ ...prev, isMenuOpen: !prev.isMenuOpen }));
            }}
         />
         <div className="col bg-light overflow-auto">
            <NavBar
               Open={control.isMenuOpen}
               setOpen={() => {
                  setControl((prev) => ({ ...prev, isMenuOpen: !prev.isMenuOpen }));
               }}
            />
            <div className="mainContainer">
               <Outlet />
            </div>
         </div>
      </div>
   );
}
