import Modal from "react-bootstrap/Modal";
import {Button} from "../Components";
import {useSelector} from "react-redux";
import store from "../Store/store";
import {HideApiMsg} from "../Store/Reducers/DisplayApiMsg";
import * as app from "../Services/AppService";
const MsgDialog = () => {
   let data = useSelector((state) => state.DisplayApiMsg.ReturnApiData);

   const Close = () => {
      store.dispatch(HideApiMsg());
   };

   setTimeout(() => {
      if (data.status === "success") store.dispatch(HideApiMsg());
   }, 2000);

   return (
      <>
         {data.show && (
            <div className="modal show" style={{display: "block", position: "absolute"}}>
               <Modal.Dialog className={`${data.status === "success" ? "mainText" : "text-danger"}`}>
                  <Modal.Header
                     className={`d-flex text-white ${data.status === "success" ? "bg-primary" : "bg-danger"}`}
                     closeButton
                     onClick={Close}
                  >
                     <Modal.Title className="col">
                        {app.translate(data.status === "success" ? "Success" : data.status === "error" && "Error")}
                     </Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="text-dark">
                     {typeof data.message === "string" ? (
                        <p>{app.translate(data.message)}</p>
                     ) : (
                        data.message.map((msg) => {
                           return <p> {app.translate(msg.message)}</p>;
                        })
                     )}
                  </Modal.Body>
                  <Modal.Footer>
                     <Button Class={`${data.status === "success" ? "btn-primary" : "btn-danger"}`} Clicked={Close}>
                        {app.translate("Close")}
                     </Button>
                  </Modal.Footer>
               </Modal.Dialog>
            </div>
         )}
      </>
   );
};

export default MsgDialog;
