import React from "react";
import PropTypes from "prop-types";
import * as app from "../Services/AppService";
export default function RadioBox(props) {
   function handler(event) {
      const {checked} = event.target;
      if (props.OnChange && typeof props.OnChange === "function") props.OnChange(checked);
   }
   return (
      <div className={props.ContainerClass}>
         <input
            className={`form-check-input ${props.CLass}`}
            type="radio"
            name={props.Name}
            disabled={props.Disabled}
            onChange={handler}
            checked={props.Model}
            id={props.Key}
         />
         {props.Label !== "" && (
            <label className={"mx-1 form-check-label" + props.LabelClass} htmlFor={props.Key}>
               {app.translate(props.Label)}
            </label>
         )}
      </div>
   );
}
RadioBox.propTypes = {
   Name: PropTypes.string,
   Label: PropTypes.string,
   ContainerClass: PropTypes.string,
   Class: PropTypes.string,
   LabelClass: PropTypes.string,
   Disabled: PropTypes.bool,
   Key: PropTypes.number,
   Model: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
   OnChange: PropTypes.func,
};
RadioBox.defaultProps = {
   Name: "",
   Label: "",
   ContainerClass: "",
   Class: "",
   LabelClass: "",
   Disabled: false,
   Model: 0,
   Key: "",
};
