import * as app from "./AppService";

const PatientType = [
   {key: 0, value: app.translate("Standard")},
   {key: 1, value: app.translate("Insurance")},
   {key: 2, value: app.translate("Charity")},
   {key: 3, value: app.translate("Vip")},
];

const SalesType = [
   {key: 0, value: app.translate("Internal")},
   {key: 1, value: app.translate("External")},
];

const PaymentType = [
   {key: 0, value: app.translate("CashAtDischarge")},
   {key: 1, value: app.translate("Debt")},
];

const SupplyType = [
   {key: 0, value: app.translate("Operations")},
   {key: 1, value: app.translate("InstantTransfer")},
];

const SalesItemType = [
   {key: 0, value: app.translate("Normal")},
   {key: 1, value: app.translate("Additional")},
   {key: 2, value: app.translate("Exchange")},
];

const BloodTypes = [
   {key: 1, value: app.translate("Blood")},
   {key: 2, value: app.translate("BloodPlatelets")},
   {key: 3, value: app.translate("Plasma")},
];

const BloodTypesEn = [
   {key: 1, value: "Blood"},
   {key: 2, value: "BloodPlatelets"},
   {key: 3, value: "Plasma"},
];

const BloodItems = [
   {key: 1, value: "A+"},
   {key: 2, value: "B+"},
   {key: 3, value: "AB+"},
   {key: 4, value: "O+"},
   {key: 5, value: "A-"},
   {key: 6, value: "B-"},
   {key: 7, value: "AB-"},
   {key: 8, value: "O-"},
];

const PatientBalanceChangeType = [
   {key: 0, value: "PayOperation"},
   {key: 1, value: "SaleOperation"},
];
const TransactionType = [
   {key: 0, value: app.translate("Add")},
   {key: 1, value: app.translate("Withdraw")},
   {key: 2, value: app.translate("Add")},
];

const SupplierBalanceChangeType = [
   {key: 0, value: "PurchaseOperation"},
   {key: 1, value: "PayOperation"},
   {key: 2, value: "Debt"},
];

export function GetFixedData(name, Id = null) {
   let Label = "";
   if (Id === null) {
      if (name === "PatientType") return PatientType;
      else if (name === "SalesType") return SalesType;
      else if (name === "PaymentType") return PaymentType;
      else if (name === "SupplyType") return SupplyType;
      else if (name === "SalesItemType") return SalesItemType;
      else if (name === "BloodTypesEn") return BloodTypesEn;
      else if (name === "BloodTypes") return BloodTypes;
      else if (name === "BloodItems") return BloodItems;
      else if (name === "PatientBalanceChangeType") return PatientBalanceChangeType;
      else if (name === "SupplierBalanceChangeType") return SupplierBalanceChangeType;
      else if (name === "TransactionType") return TransactionType;
      else return name;
   } else {
      if (name === "PatientType") {
         PatientType.map((item) => item.key == Id && (Label = item.value));
         return Label;
      } else if (name === "SalesType") {
         SalesType.map((item) => item.key == Id && (Label = item.value));
         return Label;
      } else if (name === "PaymentType") {
         PaymentType.map((item) => item.key == Id && (Label = item.value));
         return Label;
      } else if (name === "SupplyType") {
         SupplyType.map((item) => item.key == Id && (Label = item.value));
         return Label;
      } else if (name === "SalesItemType") {
         SalesItemType.map((item) => item.key == Id && (Label = item.value));
         return Label;
      } else if (name === "BloodTypes") {
         BloodTypes.map((item) => item.key == Id && (Label = item.value));
         return Label;
      } else if (name === "BloodTypesEn") {
         BloodTypesEn.map((item) => item.key == Id && (Label = item.value));
         return Label;
      } else if (name === "BloodItems") {
         BloodItems.map((item) => item.key == Id && (Label = item.value));
         return Label;
      } else if (name === "PatientBalanceChangeType") {
         PatientBalanceChangeType.map((item) => item.key == Id && (Label = item.value));
         return Label;
      } else if (name === "SupplierBalanceChangeType") {
         SupplierBalanceChangeType.map((item) => item.key == Id && (Label = item.value));
         return Label;
      } else if (name === "TransactionType") {
         TransactionType.map((item) => item.key == Id && (Label = item.value));
         return Label;
      } else return name;
   }
}
