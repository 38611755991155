import { createSlice } from "@reduxjs/toolkit"

const initialState = {
    ReturnApiData: {
        show: false,
        status: "success",//error
        message: [] || "",
    },
    ConfirmData: {
        show: false,
        message: "",
        Ok: () => { },
    }
}

const Reducers = {
    ShowApiMsg(state, action) {
        state.ReturnApiData.show = true
        state.ReturnApiData.status = action.payload.status
        state.ReturnApiData.message = action.payload.message
    },

    HideApiMsg(state) {
        state.ReturnApiData.show = false
        state.ReturnApiData.status = "success"
        state.ReturnApiData.message = "" || []
    },

    ShowConfirmedMsg(state, action) {
        state.ConfirmData.show = action.payload.show
        state.ConfirmData.Ok = action.payload.Ok
        state.ConfirmData.message = action.payload.message
    },

    HideConfirmedMsg(state) {
        state.ConfirmData.show = false
        state.ConfirmData.Ok = () => { }
        state.ConfirmData.message = ""
    }
}

const DisplayApiMsgSlice = createSlice({
    name: "DisplayApiMsg",
    initialState,
    reducers: { ...Reducers }
})

export const { ShowApiMsg, HideApiMsg, ShowConfirmedMsg, HideConfirmedMsg } = DisplayApiMsgSlice.actions;
export default DisplayApiMsgSlice.reducer;