import {useState, useEffect} from "react";
import {Link, useNavigate} from "react-router-dom";
import {Button, Input, ModalView} from "../Components";
import {IoLogOut} from "react-icons/io5";
import {RiMenu3Fill} from "react-icons/ri";
import * as app from "../Services/AppService";
import {BsPersonCircle} from "react-icons/bs";
import {Dropdown} from "react-bootstrap";
import LOGIN from "../Features/Login";
import {AiOutlineEye, AiOutlineEyeInvisible} from "react-icons/ai";
import {setLoginStatus} from "../Store/Reducers/Auth";
import {useDispatch} from "react-redux";

export default function NavBar(props) {
   const navigate = useNavigate();

   const dispatch = useDispatch();

   const WSize = app.GetWindowSize();

   let userName = JSON.parse(localStorage.getItem("user"))?.UserName;

   const initModel = {...LOGIN.MODELS.Request.ChangePassModel.Body, ConfirmPassword: ""};

   const [model, setModel] = useState(initModel);

   const [control, setControl] = useState({
      searchInput: "",
      ShowSide: false,
      ShowSearch: false,
      ShowProfile: false,
      ShowChangePassword: false,
      ShowCurrent: false,
      ShowNew: false,
      ShowConfirm: false,
   });

   function search() {}

   async function LogOut() {
      dispatch(setLoginStatus(false));
      await new Promise((resolve) => {
         localStorage.removeItem("user");
         resolve();
      });
      window.location.reload();
   }

   function Validation() {
      if (
         model.newPassword === model.ConfirmPassword &&
         model.ConfirmPassword.length > 0 &&
         model.newPassword.length > 0 &&
         model.currentPassword.length > 0
      ) {
         return true;
      } else {
         return false;
      }
   }

   function HandleSubmit() {
      if (Validation()) {
         LOGIN.ACTIONS.ChangePassword(model, (res) => {});
      } else {
         app.DisplayApiMsg("PasswordNotMatch", "Warning");
      }
   }
   useEffect(() => {}, [control.isMenuOpen]);
   return (
      <div className="d-flex">
         <nav className="navbar navbar-expand-lg bg-white shadow col-12" style={{height: "70px"}}>
            <div className="container-fluid">
               <div className="navbar-brand d-flex align-items-center m-0 ">
                  <RiMenu3Fill
                     className="d-sm-none d-block"
                     size={25}
                     style={{cursor: "pointer"}}
                     onClick={() => {
                        props.setOpen();
                     }}
                  />
                  {!props.Open && (
                     <Link to="/" className="">
                        <img src="/Logo.svg" alt="Blood Bank Logo" />
                     </Link>
                  )}
               </div>
               {app.CheckLogin() && (
                  <div className="d-flex align-items-center" role="search">
                     <div className="flex align-items-center">
                        {app.CheckLogin() && (
                           <>
                              <Dropdown className="d-inline-block">
                                 <Dropdown.Toggle className="bg-white border-0" id="dropdown-basic">
                                    <BsPersonCircle color="gray" size={20} />
                                 </Dropdown.Toggle>

                                 <Dropdown.Menu className="bg-white  p-0 shadow">
                                    <Dropdown.Item className="text-primary disabled">
                                       {`${app.translate("UserName")}:  `}
                                       <span className="fw-bold">{userName}</span>
                                    </Dropdown.Item>
                                    <hr className="my-1" />
                                    <Dropdown.Item
                                       className="text-primary text-center btn-outline-primary"
                                       onClick={() => {
                                          setControl((prev) => ({
                                             ...prev,
                                             ShowChangePassword: !prev.ShowChangePassword,
                                          }));
                                       }}
                                    >
                                       {app.translate("ChangePassword")}
                                    </Dropdown.Item>
                                 </Dropdown.Menu>
                              </Dropdown>

                              <Button Clicked={LogOut} Icon={true} Class="shadow-none p-0 ">
                                 <IoLogOut fill="gray" size={20} style={{transform: "rotate(180deg)"}} />
                              </Button>
                           </>
                        )}
                     </div>
                  </div>
               )}
            </div>
            <ModalView
               Show={control.ShowChangePassword}
               Title="ChangePassword"
               BtnTitle={"Save"}
               mainBtnClass="btn-outline-primary"
               Control={() => setControl((prev) => ({...prev, ShowChangePassword: !prev.ShowChangePassword}))}
               Cancel={() => setModel(initModel)}
               Class="d-flex justify-content-between"
               Submit={HandleSubmit}
               BtnStatus={Validation()}
            >
               <Input
                  Type={control.ShowCurrent ? "text" : "password"}
                  Label="CurrentPassword"
                  Model={model.currentPassword}
                  OnChange={(newValue) => setModel((prev) => ({...prev, currentPassword: newValue}))}
                  Icon
               >
                  <Button
                     Clicked={() => {
                        setControl((prev) => ({...prev, ShowCurrent: !prev.ShowCurrent}));
                     }}
                  >
                     {" "}
                     {control.ShowCurrent ? <AiOutlineEye className="text-primary" /> : <AiOutlineEyeInvisible />}
                  </Button>
               </Input>

               <Input
                  Type={control.ShowNew ? "text" : "password"}
                  Label="NewPassword"
                  Model={model.newPassword}
                  InputContainerClass={model.ConfirmPassword !== model.newPassword ? " border-danger mx-2" : "mx-2"}
                  OnChange={(newValue) => setModel((prev) => ({...prev, newPassword: newValue}))}
                  Icon
               >
                  <Button
                     Clicked={() => {
                        setControl((prev) => ({...prev, ShowNew: !prev.ShowNew}));
                     }}
                  >
                     {" "}
                     {control.ShowNew ? <AiOutlineEye className="text-primary" /> : <AiOutlineEyeInvisible />}
                  </Button>
               </Input>
               <Input
                  Type={control.ShowNew ? "text" : "password"}
                  Label="ConfirmPassword"
                  Model={model.ConfirmPassword}
                  InputContainerClass={model.ConfirmPassword !== model.newPassword ? " border-danger mx-2" : "mx-2"}
                  OnChange={(newValue) => setModel((prev) => ({...prev, ConfirmPassword: newValue}))}
                  Icon
               >
                  <Button
                     Clicked={() => {
                        setControl((prev) => ({...prev, ShowConfirm: !prev.ShowConfirm}));
                     }}
                  >
                     {" "}
                     {control.ShowConfirm ? <AiOutlineEye className="text-primary" /> : <AiOutlineEyeInvisible />}
                  </Button>
               </Input>
            </ModalView>
         </nav>
      </div>
   );
}
