import React from "react";
import Select from "../Select.js";
import PropTypes from "prop-types";
import {SlArrowLeft, SlArrowRight} from "react-icons/sl";

export default function TablePagination(props) {
   return (
      <div className="row g-0 my-2 align-items-center justify-content-center">
         <div className={`col-auto d-flex flex-wrap align-items-center justify-content-center`}>
            <Select
               Label={"ShowedRecord"}
               LabelClass={"text-secondary small"}
               Name="pageSize"
               ContainerClass="col-auto"
               Class={"form-select-sm border-light shadow-none text-secondary w-auto"}
               Options={props.model.possiblePageSizes}
               Model={props.model.pageSize}
               OnChange={props.PageSizeHandle}
            />
            <div className="mx-3">{!props.HideTotalCount && props.rowCountElement}</div>
         </div>

         <div className="col d-flex justify-content-center justify-content-sm-end mt-2 mt-sm-0 ms-1">
            {+props.Count > props.model.pageSize && (
               <nav aria-label="Page navigation ">
                  <ul className="pagination pagination-sm m-0">
                     <li className="page-item">
                        <span
                           className="page-link bg-secondary text-white"
                           style={{cursor: "pointer"}}
                           onClick={() => {
                              props?.PageIndexHandle(props?.model?.pageIndex - 1);
                           }}
                        >
                           <SlArrowRight />
                        </span>
                     </li>
                     <li className="d-flex align-items-center mx-1 ">
                        <span className="text-secondary mx-1">{props.model.pageIndex}</span>/
                        <span
                           className={
                              props?.model?.pageIndex === props?.model?.totalPages
                                 ? "mx-1 text-dark"
                                 : "mx-1 text-secondary"
                           }
                        >
                           {+props?.model?.totalPages} 
                        </span>
                     </li>
                     <li className="page-item">
                        <span
                           className="page-link bg-primary text-white"
                           style={{cursor: "pointer"}}
                           onClick={() => {
                              props?.PageIndexHandle(props?.model?.pageIndex + 1);
                           }}
                        >
                           <SlArrowLeft />
                        </span>
                     </li>
                  </ul>
               </nav>
            )}
         </div>
      </div>
   );
}

TablePagination.propTypes = {
   model: PropTypes.object.isRequired,
   PageSizeHandle: PropTypes.func.isRequired,
   HideTotalCount: PropTypes.bool.isRequired,
   Count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired, // allow string or number
};
