import Modal from "react-bootstrap/Modal";
import {Button} from "../Components";
import {useSelector} from "react-redux";
import store from "../Store/store";
import {HideConfirmedMsg, ShowConfirmedMsg} from "../Store/Reducers/DisplayApiMsg";
import * as app from "../Services/AppService";
const ConfirmDeletion = (props) => {
   var data = useSelector((state) => state.DisplayApiMsg.ConfirmData);

   const Close = () => {
      store.dispatch(HideConfirmedMsg());
   };

   const Ok = () => {
      store.dispatch(
         ShowConfirmedMsg({
            show: false,
            message: "AreYouSureDelete",
            Ok: data.Ok(),
         })
      );
   };
   return (
      <>
         {data.show && (
            <div className="modal show" style={{display: "block", position: "absolute"}}>
               <Modal.Dialog className="text-warning">
                  <Modal.Header className="d-flex text-white bg-danger" closeButton onClick={Close}>
                     <Modal.Title className="col">{app.translate("Warning")}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body className="text-dark">
                     <p>{app.translate(data.message)}</p>
                  </Modal.Body>
                  <Modal.Footer>
                     <Button Class="btn-outline-secondary" Clicked={Close}>
                        {app.translate("Close")}{" "}
                     </Button>
                     <Button Class="btn-outline-danger" Clicked={Ok}>
                        {app.translate("Ok")}
                     </Button>
                  </Modal.Footer>
               </Modal.Dialog>
            </div>
         )}
      </>
   );
};

export default ConfirmDeletion;
