import React from "react";
import PropTypes from "prop-types";
import {FaBeer} from "react-icons/fa";
const Card = (props) => {
   return (
      <div className={`card ${props.CardClass}`} style={{}}>
         {props.Icon && <div className={`${props.IconClass}`}>{props.Icon}</div>}
         <div className={`${props.LabelClass}`}>
            <div className="">{props.Name}</div>
            {props.Title !== "" && <div className="fs-6">{props.Title}</div>}
         </div>
         {props.children}
      </div>
   );
};

export default Card;

Card.propTypes = {
   CardClass: PropTypes.string,
   Img: PropTypes.string,
   Icon: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
   LabelClass: PropTypes.string,
   Title: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
   ]),
   Name: PropTypes.string,
   IconClass: PropTypes.string,
};

Card.defaultProps = {
   Img: "", //"Group 6.png"
   Name: "", //"Test"
   Title: "", //"test hospital"
   CardClass: "rounded rounded-4",
   Icon: "",
   IconClass: "",
   LabelClass: "",
};
