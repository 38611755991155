import React from "react";
import PropTypes from "prop-types";
import * as app from "../Services/AppService";

export default function StatisticDiv(props) {
   return (
      <div className={` ${props.Class}`}>
         <div className={`text-end my-1 ${props.LabelClass}`}>
            {app.translate(props.Label)} :
            <div className={`d-inline-block mx-2 fw-bold ${props.ResultClass}`}>{props.Result}</div>
         </div>
      </div>
   );
}

StatisticDiv.propTypes = {
   Label: PropTypes.string.isRequired,
   Result: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
   Class: PropTypes.string,
   LabelClass: PropTypes.string,
   ResultClass: PropTypes.string,
};

StatisticDiv.defaultProps = {
   Class: "col-12 col-lg-auto bg-light border-primary shadow-sm m-1 mx-1  rounded border border-1",
   LabelClass: "text-black fw-normal",
   ResultClass: "text-black ",
};
