import React, { useState } from 'react'
import * as app from "../../Services/AppService"
import { Input, Button } from "../../Components"
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai"
import LOGIN from "../../Features/Login"
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { setLoginStatus, setUserInfo } from '../../Store/Reducers/Auth'
import { useEffect } from 'react'
import * as http from '../../Services/http';
import { BASE_SITE_URL } from '../../Utils/constants';
import { Config } from '../../Features/Login/OAuthConfiguration'
const Login = (props) => {
   let Navigate = useNavigate();

    const dispatch = useDispatch();
    const location = useLocation();
    const [control, setControl] = useState({
        show: false
    })

    const [model, setModel] = useState({
        ...LOGIN.MODELS.Request.Model.Body
    })

    useEffect(() => {
        if (true) {

            http.TokenPost(BASE_SITE_URL + '/connect/token', {

                redirect_uri: Config.RedirectUri,
                client_secret: Config.ClientSecret,
                grant_type: Config.GrantType,
                code: window.location.href.substring(window.location.href.indexOf('code=')).replace('code=', ''),
                client_id: Config.ClientId

            }
            , (res) => {

                if (res != undefined) {

                    dispatch(setUserInfo({...res,IsLoggedIn:true,UserName:model.userNameOrEmailAddress}))
                    localStorage.setItem('user', JSON.stringify({ ...res, IsLoggedIn: true }));

                }


            })
        }

    }, [window.location.href])


    async function PostApi() {

        LOGIN.ACTIONS.Login({
            ...model
        }, (res) => {
                  //              dispatch(setUserInfo({...res,IsLoggedIn:true,UserName:model.userNameOrEmailAddress}))
                  //   localStorage.setItem('user', JSON.stringify({ ...res, IsLoggedIn: true }));
            Navigate('/')
        })
    }

   return (
      <div className="container">
         <div className="row justify-content-center my-5 mx-0">
            <div className="col-lg-5 col-12 shadow rounded rounded-3">
               <div className="text-center text-primary fw-bold my-3"> {app.translate("Login")}</div>
               <div className="py-4">
                  <Input
                     Label="UserName"
                     Placeholder="UserName"
                     ContainerClass="my-1 flex-lg-row flex-column"
                     LabelClass="col-lg-3 col-12"
                     InputContainerClass="col-lg col-12"
                     OnChange={(userNameOrEmailAddress) => {
                        setModel((old) => ({...old, userNameOrEmailAddress}));
                     }}
                     Model={model.userNameOrEmailAddress}
                  />
                  <Input
                     Label="Password"
                     Placeholder="Password"
                     Type={!control.show ? "password" : "text"}
                     ContainerClass="my-1 flex-lg-row flex-column"
                     LabelClass="col-lg-3 col-12"
                     InputContainerClass="col-lg col-12"
                     Icon
                     OnChange={(password) => {
                        setModel((old) => ({...old, password}));
                     }}
                     Model={model.password}
                     OnKeyDown={(value) => {
                        if (value.key === "Enter") {
                           PostApi();
                        }
                     }}
                  >
                     <Button
                        Clicked={() => {
                           setControl((old) => ({...old, show: !old.show}));
                        }}
                     >
                        {" "}
                        {control.show ? <AiOutlineEye className="text-primary" /> : <AiOutlineEyeInvisible />}
                     </Button>
                  </Input>
                  <div className="row justify-content-center">
                     <Button Label="Login" Class="btn-primary text-light w-auto my-1" Clicked={PostApi} />
                  </div>
               </div>
            </div>
         </div>
      </div>
   );
};
export default Login;
