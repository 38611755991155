import PropTypes from "prop-types";
import {CSVLink} from "react-csv";
import app from "../Services/AppService";
import Button from "./Button";
import {Link} from "react-router-dom";

export default function ExportBtn(props) {
   if (props.data && Array.isArray(props.data) && props.data.length > 0) {
      const headers = props.Headers
         ? props?.Headers?.map((header) => {
              const label =
                 Object.prototype.toString.call(header) === "[object Object]"
                    ? app.translate(header.label)
                    : app.translate(header);
              const key = Object.prototype.toString.call(header) === "[object Object]" ? header.key : header;
              return {label, key};
           })
         : Object.keys(props.data[0])?.map((header) => ({
              label: app.translate(header),
              key: header,
           }));

      const transformedData = props.Options
         ? props.data?.map((item) => {
              const transformedItem = {};
              for (const [key, value] of Object.entries(item)) {
                 const option = props.Options.find((o) => o.name === key);
                 if (option) {
                    if (option.Data) {
                       transformedItem[key] = app.translate(app.GetStaticDataById(option.Data, item[`${option.name}`]));
                    } else transformedItem[key] = app.translate(option[value]);
                 } else transformedItem[key] = app.translate(value);
              }
              return transformedItem;
           })
         : props.data;

      return (
         <CSVLink
            data={transformedData}
            headers={headers}
            filename={app.translate(props.fileName)}
            className={`btn ${transformedData?.length === 0 && "disabled"} ${props.Class}`}
         >
            {!props.children ? app.translate("Export") : props?.children}
         </CSVLink>
      );
   } else return;
}

ExportBtn.propTypes = {
   data: PropTypes.array,
   Headers: PropTypes.array,
   Class: PropTypes.string,
   fileName: PropTypes.string,
   Options: PropTypes.arrayOf(
      PropTypes.shape({
         name: PropTypes.string,
         [PropTypes.string]: PropTypes.string,
      })
   ),
};

ExportBtn.defaultProps = {
   Class: "",
   fileName: "Excel",
};
