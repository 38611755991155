import {lazy} from "react";
export const PrivateList = [
   //Public :
   {path: "/", element: lazy(() => import("../Pages/Home"))},
   {path: "*", element: lazy(() => import("../Pages/Home"))},

   //Hospitals :
   {path: "/Hospitals", element: lazy(() => import("../Pages/Hospitals/Hospitals"))},
   {path: "/Hospitals/HospitalsDetails/:id", element: lazy(() => import("../Pages/Hospitals/HospitalDetails"))},

   //Patients :
   {path: "/Patients", element: lazy(() => import("../Pages/Patients"))},
   {path: "/AddPatient/:Name", element: lazy(() => import("../Pages/Patients/AddPatient"))},
   {path: "/PatientDetails", element: lazy(() => import("../Pages/Patients/PatientDetails"))},
   {path: "/UpdatePatientSale", element: lazy(() => import("../Pages/Patients/UpdatePatientSale"))},

   //Suppliers :
   {path: "/Suppliers", element: lazy(() => import("../Pages/Suppliers"))},
   {path: "/AddSupplier", element: lazy(() => import("../Pages/Suppliers/AddSupplier"))},
   {path: "/SupplierDetails", element: lazy(() => import("../Pages/Suppliers/SupplierDetails"))},

   //Warehouse :
   {path: "/Warehouse", element: lazy(() => import("../Pages/Warehouse/Warehouse"))},
   {path: "/Warehouse/AddDamagedMaterial", element: lazy(() => import("../Pages/Warehouse/DamagedMaterial"))},

   //Purchases :
   {path: "/Purchases", element: lazy(() => import("../Pages/Purchases"))},
   {path: "/AddPurchase", element: lazy(() => import("../Pages/Purchases/AddPurchase"))},

   //Sales :
   {path: "/Sales", element: lazy(() => import("../Pages/Sales"))},
   {path: "/AddSale", element: lazy(() => import("../Pages/Sales/AddSale"))},

   //Accounts :
   {path: "/Accounts", element: lazy(() => import("../Pages/Accounts"))},
   {path: "/Accounts/HospitalsBalances", element: lazy(() => import("../Pages/Accounts/HospitalsBalances"))},
   {path: "/Accounts/HospitalsBalances/:id", element: lazy(() => import("../Pages/Accounts/PayOffHospital"))},
   {path: "/Accounts/OswaTechBudget", element: lazy(() => import("../Pages/Accounts/OswaTechBudget/OswaTechBudget"))},
   {path: "/Accounts/PatientsBalances", element: lazy(() => import("../Pages/Accounts/PatientsBalances"))},
   // {path: "/Accounts/PurchasesBalances", element: lazy(() => import("../Pages/Accounts/PurchasesBalances"))},
   // {path: "/Accounts/SalesBalances", element: lazy(() => import("../Pages/Accounts/SalesBalances"))},
   {path: "/Accounts/SuppliersBalances", element: lazy(() => import("../Pages/Accounts/SuppliersBalances"))},
   {path: "/Account/Expenses", element: lazy(() => import("../Pages/Accounts/Expenses/ExpensesBalances"))},
   {path: "/Account/AddExpense", element: lazy(() => import("../Pages/Accounts/Expenses/AddExpense"))},
   {path: "/PayOff", element: lazy(() => import("../Pages/Accounts/PayOff"))},
   {path: "/PayOffPatient", element: lazy(() => import("../Pages/Accounts/PayOffPatient"))},

   //Partners :
   {path: "/Partners", element: lazy(() => import("../Pages/Partners"))},
   {path: "/AddPartner", element: lazy(() => import("../Pages/Partners/AddPartner"))},
   {path: "/Partners/PartnerDetails", element: lazy(() => import("../Pages/Partners/PartnerDetails"))},
   {path: "/Partners/AddTransaction", element: lazy(() => import("../Pages/Partners/AddTransaction"))},

   //Reports :
   {path: "/Reports", element: lazy(() => import("../Pages/Reports"))},
   {path: "/Reports/HospitalsReports", element: lazy(() => import("../Pages/Reports/HospitalsReports/HospitalsView"))},
   {
      path: "/Reports/HospitalsReports/:id",
      element: lazy(() => import("../Pages/Reports/HospitalsReports/HospitalsReports")),
   },

   {path: "/Reports/PaitentReports", element: lazy(() => import("../Pages/Reports/PaitentReports"))},
   {
      path: "/PaitentReports/PatientAccountStatement",
      element: lazy(() => import("../Pages/Reports/PaitentReports/PatientAccountStatement")),
   },

   {path: "/Reports/PartnersReports", element: lazy(() => import("../Pages/Reports/PartnersReports/PartnersView"))},

   {
      path: "Reports/PartnersReports/:id",
      element: lazy(() => import("../Pages/Reports/PartnersReports/PartnersReports")),
   },

   {path: "/Reports/SuppliersReports", element: lazy(() => import("../Pages/Reports/SuppliersReports"))},
   {
      path: "/SuppliersReports/SupplierAccountStatement",
      element: lazy(() => import("../Pages/Reports/SuppliersReports/SupplierAccountStatement")),
   },
];
export const publicRouteList = [
   { path: "/", element: lazy(() => import("../Pages/Auth/Login")), routeParam: "code=" },
   { path: "*", element: lazy(() => import("../Pages/Auth/Login")), routeParam: "code=" },
   //{ path: "/login", element: lazy(() => import("../Pages/Auth/Login")) },
];
