import React from "react";
import PropTypes from "prop-types";
// import Button from "../Button";
import * as app from "../Services/AppService";

function Select(props) {
   function handler(event) {
      const {value} = event.target;
      if (props.OnChange && typeof props.OnChange == "function") props.OnChange(value);
   }

   return (
      <div className={`d-flex flex-lg-row  align-items-center ${props.ContainerClass}`}>
         {props.Label && <div className={`${props.LabelClass}`}>{app.translate(props.Label)}</div>}
         <select
            className={`form-select ${props.Class}`}
            disabled={props.Disabled}
            onChange={handler}
            value={props.Model}
         >
            {props.SelectAll && props.Options.find((item) => +item[props.Key] === 0) === undefined && (
               <option defaultValue value="0">
                  {app.translate(props.FirstSelect)}
               </option>
            )}
            {props.Options.length > 0 &&
               props.Options.map((item) => {
                  return (
                     <option value={item[props.Key]} key={item[props.Key]}>
                        {item[props.Value]}
                     </option>
                  );
               })}
         </select>
      </div>
   );
}

Select.propTypes = {
   ContainerClass: PropTypes.string,
   LabelClass: PropTypes.string,
   Label: PropTypes.string,
   Class: PropTypes.string,
   Disabled: PropTypes.bool,
   OnChange: PropTypes.func,
   Model: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]),
   Options: PropTypes.array.isRequired,
   Key: PropTypes.string,
   Value: PropTypes.string,
   FirstSelect: PropTypes.string,
   SelectAll: PropTypes.bool,
};

Select.defaultProps = {
   ContainerClass: "mx-2",
   LabelClass: "",
   Class: "",
   Disabled: false,
   Key: "Key",
   Value: "Value",
   FirstSelect: "PleaseSelect",
   SelectAll: false,
};

export default Select;
