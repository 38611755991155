// export default function Spinner(props) {
//    return (
//       props.Loading && (
//          <div
//             className="w-100 bg-light d-flex align-items-center justify-content-center vh-100"
//          >
//             <div className="align-items-center d-flex justify-content-center w-100 h-50 bg-dark opacity-25">
//                <p className="spinner-border text-danger">.</p>
//             </div>
//          </div>
//       )
//    );
// }


import { BeatLoader } from "react-spinners";
const override = {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   background: "#eee",
   height: "300px",
   zIndex: "2",
};

export default function Spinner(props) {
   return (
      props.Loading && <div className="row mx-0 m-auto align-items-center vh-100">
         <BeatLoader
            cssOverride={override}
            color="#36d7b7"
            size={20}
            loading={true}
            speedMultiplier={0.35}
         />
      </div>
   );
}

