import { configureStore, combineReducers } from "@reduxjs/toolkit";
import SpinnerReducer from "../Store/Reducers/SpinnerSlice";
import AuthReducer from "./Reducers/Auth";
import DisplayApiMsgReducer from "./Reducers/DisplayApiMsg"
// import { getDefaultMiddleware } from '@reduxjs/toolkit';
const reducers = combineReducers({
   Spinner: SpinnerReducer,
   Auth: AuthReducer,
   DisplayApiMsg: DisplayApiMsgReducer,
});

export default configureStore({
   reducer: reducers,
   middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
         serializableCheck: false,
      }),
});
