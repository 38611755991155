import PropTypes from "prop-types";
import {Modal} from "react-bootstrap";
import Button from "./Button";
import app from "../Services/AppService";
export default function ModalView(props) {
   return (
      <Modal show={props.Show} className={`${props.Class}`} centered>
         <Modal.Header>
            <Modal.Title>{app.translate(props.Title)}</Modal.Title>
         </Modal.Header>
         {props.Body && <Modal.Body className={`${props.BodyClass}`}>{props.children}</Modal.Body>}
         <Modal.Footer className="d-flex justify-content-between">
            {props.CancelBtn && (
               <Button
                  Clicked={() => {
                     props.Cancel();
                     props.Control();
                  }}
                  Label="Canceled"
                  Class={"btn-secondary"}
               />
            )}
            <Button
               Clicked={() => {
                  props.Cancel();
                  props.Control();
                  props.Submit();
               }}
               Label={props.BtnTitle}
               Class={props.mainBtnClass}
               Disabled={props.BtnStatus}
            />
         </Modal.Footer>
      </Modal>
   );
}

ModalView.propTypes = {
   Show: PropTypes.bool,
   Class: PropTypes.string,
   BodyClass: PropTypes.string,
   Title: PropTypes.string,
   BtnTitle: PropTypes.string,
   Submit: PropTypes.func.isRequired,
   Control: PropTypes.func.isRequired,
   Cancel: PropTypes.func,
   Body: PropTypes.bool,
   CancelBtn: PropTypes.bool,
   mainBtnClass: PropTypes.string,
   BtnStatus: PropTypes.bool,
};

ModalView.defaultProps = {
   Class: "",
   BodyClass: "",
   Body: true,
   CancelBtn: true,
   mainBtnClass: "btn-primary",
   BtnStatus: false,
};
