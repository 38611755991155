import React from "react";
import {CChart} from "@coreui/react-chartjs";
import PropTypes from "prop-types";
import * as app from "../Services/AppService";
const Chart = (props) => (
   <>
      {props.Data.length > 0 && (
         <CChart
            className=" p-2 my-2 mx-1 rounded"
            customTooltips={false}
            type={props.Type}
            data={{
               labels: [...props.Labels],
               datasets: [
                  {
                     label: app.translate(props.Title),
                     backgroundColor: props.BackgroundColor,
                     borderColor: props.BorderColor,
                     pointBackgroundColor: props.PointBackgroundColor,
                     pointBorderColor: props.PointBorderColor,
                     data: [...props.Data],
                  },
               ],
            }}
         />
      )}
   </>
);

export default Chart;

Chart.propTypes = {
   Type: PropTypes.string,
   Labels: PropTypes.array,
   Data: PropTypes.array,
   Title: PropTypes.string,
   BackgroundColor: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
   BorderColor: PropTypes.string,
   PointBackgroundColor: PropTypes.string,
   PointBorderColor: PropTypes.string,
};

Chart.defaultProps = {
   Type: "doughnut", // {'line' | 'bar' | 'radar' | 'doughnut' | 'polarArea' | 'bubble' | 'pie' | 'scatter'}
   Labels: ["January", "February", "March", "April", "May", "June", "July"],
   Title: "First Title",
   BackgroundColor: "rgba(151, 187, 205, 0.2)",
   BorderColor: "rgba(151, 187, 205, 1)",
   PointBackgroundColor: "rgba(151, 187, 205, 1)",
   PointBorderColor: "#fff",
   Data: [50, 12, 28, 29, 7, 25, 12, 70, 60],
};
