import React, { Suspense } from 'react';
import { Route } from 'react-router-dom';
import Spinner from '../Utils/Spinner';

const RoutesRenderer = (RouteArray) => RouteArray.map((route, index) =>
    <Route
        key={index}
        // exact
        // path={route.path}
        exact path={route.path }
        element={
            <Suspense fallback={<Spinner />}>
                <route.element />
            </Suspense>} />
)
export default RoutesRenderer;