import PropTypes from "prop-types";
import * as app from "../Services/AppService";
function Input(props) {
   function handler(event) {
      const {value} = event.target;
      if (props.OnChange && typeof props.OnChange == "function") {
         props.OnChange(value);
      }
   }

   function handleFocus(event) {
      if (props.Type === "number") {
         event.target.select();
      }
   }

   function handleKeyDown(event) {
      if (props.Type === "number") {
         // Reject the letter 'e' and symbol characters '-', '*', '/', '+', '.', and '\'
         if (
            event.key === "e" ||
            event.key === "+" ||
            event.key === "-" ||
            event.key === "*" ||
            event.key === "/" ||
            event.key === "." ||
            event.key === "\\"
         ) {
            event.preventDefault();
         }
      }

      // Call props.OnKeyDown if it exists
      if (props.OnKeyDown && typeof props.OnKeyDown === "function") {
         props.OnKeyDown(event);
      }
   }

   return (
      <div className={`d-flex flex-lg-row  align-items-center ${props.ContainerClass}`}>
         {props.Label && <label className={`${props.LabelClass}`}>{app.translate(props.Label)}</label>}
         <div className={`d-flex  border border-2 rounded ${props.InputContainerClass}`}>
            <input
               type={props.Type}
               key={props.Key}
               className={`form-control form-control-sm border-0 ${props.Class}`}
               disabled={props.Disabled}
               onChange={handler}
               onFocus={handleFocus}
               value={props.Model}
               // onKeyDown={props.OnKeyDown}
               onKeyDown={handleKeyDown}
               placeholder={app.translate(props.Placeholder)}
               dir={props.Dir}
               required={props.isRequired}
            />
            {props.Icon && props.children && <div className={`mx-2 ${props.IconClass}`}>{props.children}</div>}
         </div>
      </div>
   );
}

Input.propTypes = {
   ContainerClass: PropTypes.string,
   InputContainerClass: PropTypes.string,
   Icon: PropTypes.bool,
   IconClass: PropTypes.string,
   Type: PropTypes.string,
   Key: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
   Class: PropTypes.string,
   Disabled: PropTypes.bool,
   Model: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
   isRequired: PropTypes.bool,
   OnBlur: PropTypes.func,
   Placeholder: PropTypes.string,
   Label: PropTypes.string,
   LabelClass: PropTypes.string,
   Dir: PropTypes.string,
   OnChange: PropTypes.func,
   OnKeyDown: PropTypes.func,
};

Input.defaultProps = {
   ContainerClass: "m-1",
   InputContainerClass: "mx-2",
   IconClass: "",
   Icon: false,
   Type: "text",
   Class: "col",
   Disabled: false,
   Placeholder: "",
   LabelClass: "col",
   Dir: "rtl",
   isRequired: false,
};

export default Input;
