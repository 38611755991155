export const Type = "Object";
export const Model = {
   Params: {},
   Body: {
      userNameOrEmailAddress: "",
      password: "",
      rememberMe: true,
   },
};
export const ChangePassModel = {
   Params: {},
   Body: {
      currentPassword: "",
      newPassword: "",
   },
};
