import React from "react";
import PropTypes from "prop-types";
import * as app from "../../Services/AppService";
import "./Tabs.css";
export default function Tabs(props) {
   function selectedChangedHandler(item) {
      if (props.onSelect) props.onSelect(item.id);
   }
   var children = props.children[0] != undefined ? props.children : [props.children];

   return (
      <>
         <ul className="nav nav-tabs mx-0 mt-4">
            {children.map((child, index) => (
               <li className="nav-item" key={child.props.id}>
                  <button
                     className={`nav-link link-dark ${
                        props.activeTab === child.props.id || (!props.activeTab && index == 0)
                           ? "active mainText"
                           : " text-primary"
                     }`}
                     onClick={() => selectedChangedHandler(child.props)}
                     disabled={child.props.disabled}
                  >
                     {app.translate(child.props.label)}
                  </button>
               </li>
            ))}
         </ul>
         <div
            className={`tab-content px-3 border border-top-0 border-1 overflow-auto position-relative ${
               props.activeTab && "bg-white"
            }`}
         >
            {children.map((tab, index) => (
               <div
                  className={`tab-pane fade ${
                     props.activeTab === tab.props.id || (!props.activeTab && index == 0) ? " show active" : ""
                  }`}
                  key={index}
                  {...tab.props}
               ></div>
            ))}
         </div>
      </>
   );
}

Tabs.propTypes = {
   onSelect: PropTypes.func,
   activeTab: PropTypes.string,
};
