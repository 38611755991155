import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    IsLoggedIn: false,
    UserName: '',
    Password: '',
    access_token: ''
};

const reducers = {
    setLoginStatus(state, action) {
        state.IsLoggedIn = action.payload;
    },
    setUserInfo(state, action) {
        state.access_token = action.payload.access_token
        state.UserName=action.payload.UserName
        state.IsLoggedIn = action.payload.IsLoggedIn;

    }
};

export const AuthSlice = createSlice({
    name: "Auth",
    initialState,
    reducers: { ...reducers },
});

export const { setLoginStatus, setUserInfo } = AuthSlice.actions;

export default AuthSlice.reducer;
