import {useEffect, useState} from "react";
import translations from "../translations.json";
import store from "../Store/store";
import {setSpinnerStatus} from "../Store/Reducers/SpinnerSlice";
import {ShowApiMsg, ShowConfirmedMsg} from "../Store/Reducers/DisplayApiMsg";
import {GetFixedData} from "./StaticData";

export function ShowSpinner() {
   store.dispatch(setSpinnerStatus(true));
}

export function HideSpinner() {
   store.dispatch(setSpinnerStatus(false));
}

export function translate(key) {
   const language = "ar";
   const word = translations[language][key] || key;
   return word;
}

export function CheckLogin() {
   return JSON.parse(localStorage.getItem("user"))?.IsLoggedIn;
}

export function getCookie(name, defValue) {
   var nameEQ = name + "=";
   
   var ca = document.cookie.split(";");
   for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) == " ") c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
   }
   return defValue;
}

export function GetWindowSize() {
   const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
   });
   useEffect(() => {
      function handleResize() {
         setWindowSize({
            width: window.innerWidth,
            height: window.innerHeight,
         });
      }
      window.addEventListener("resize", handleResize);
      handleResize();
      return () => window.removeEventListener("resize", handleResize);
   }, []);
   return windowSize;
}

export function DisplayApiMsg(Message, Status) {
   store.dispatch(
      ShowApiMsg({
         status: Status,
         message: Message,
      })
   );
}

export function ConfirmDeletion(Title = "AreYouSureDelete", ok) {
   store.dispatch(
      ShowConfirmedMsg({
         show: true,
         message: Title,
         Ok: ok,
      })
   );
}

export const GetStaticData = (Name) => {
   return GetFixedData(Name);
};

export const GetStaticDataById = (Name, Id) => {
   return GetFixedData(Name, Id);
};

export function FormatDateDayMonth(dateString) {
   const date = new Date(dateString);

   const day = date.getDate();
   const monthIndex = date.getMonth();
   const year = date.getFullYear();

   // Define month names in Arabic
   const monthNames = [
      "كانون الثاني",
      "شباط",
      "آذار",
      "نيسان",
      "أيار",
      "حزيران",
      "تموز",
      "آب",
      "أيلول",
      "تشرين الأول",
      "تشرين الثاني",
      "كانون الأول",
   ];

   const monthName = monthNames[monthIndex];

   const formattedDate = `${day} ${monthName}`;
   return formattedDate;
}

export function StandardDateFormatter(dateString) {
   let date = new Date(dateString);
   let formattedDate = date
   .toLocaleDateString("en-US", {
      year: "numeric",
      day: "2-digit",
      month: "2-digit",
   })
   .split("/")
   .join("-");
   return formattedDate;
}

export function removeTimeFromDate(date) {
   const indexOfT = date.indexOf("T");
   return date.substring(0, indexOfT);
}

const app = {
   ShowSpinner,
   HideSpinner,
   translate,
   CheckLogin,
   getCookie,
   GetWindowSize,
   DisplayApiMsg,
   ConfirmDeletion,
   GetStaticData,
   GetStaticDataById,
   FormatDateDayMonth,
   StandardDateFormatter,
   removeTimeFromDate
};

export default app;
