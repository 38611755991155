import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "../Pages/Auth/Login";
import { useSelector } from "react-redux";

import * as route from "./RouteList";
import RoutesRenderer from "./RouterRenderer";
import { UserLayout } from "../Layout";
import NavBar from "../Layout/NavBar";
export default function AppRoutes() {

   let isLoggedIn = useSelector(state => state.Auth.IsLoggedIn)
   return (
      <Routes>
         <Route element={(isLoggedIn) ? <UserLayout /> : <><NavBar /><Login /></>}>
            {RoutesRenderer((isLoggedIn) ? route.PrivateList : route.publicRouteList)}
         </Route>
      </Routes>
   );
}
