
import * as http from "../../Services/http";
import * as app from "../../Services/AppService";
import { Config } from './OAuthConfiguration';
import { BASE_SITE_URL } from '../../Utils/constants';

let Url = "/account/login";
let Url_changePass = "/account/my-profile/change-password";
export default function Login(model, OkCb) {

   // window.location.href=BASE_SITE_URL+
   // "/Abp/Languages/Switch?culture=ar&uiCulture=ar&returnUrl="+Config.RedirectUri;

      //    fetch(BASE_SITE_URL+"/Abp/Languages/Switch?culture=ar&uiCulture=ar&returnUrl="+Config.RedirectUri, {
      //    method: "get",
      //    headers: {
      //       "Content-Type": "application/json",
      //    },
      // })

   app.ShowSpinner();
   return http.Post(Url, model, (res) => {
      if (res.description !== "Success") {
         app.DisplayApiMsg(res.description, "Warring");
         return;
      }
      // app.ShowSpinner();
      // return fetch(
      //    BASE_SITE_URL+
      // '/connect/authorize?client_id='+Config.ClientId
      // +
      // '&response_type='+Config.ResponseType
      // +
      // '&scope='+Config.Scope
      // +
      // '&redirect_uri='+Config.RedirectUri,
      //    {
      //       method: "get",
      //       // credentials: 'include',
      //       headers: {
      //          "Content-Type": "application/json",
      //       },
      //    }
      // ).then((response) => {
      //    if (response.redirected) {
      //       return http.TokenPost(
      //          "/connect/token",
      //          {
      //             redirect_uri: Config.RedirectUri,
      //             client_secret: Config.ClientSecret,
      //             grant_type: Config.GrantType,
      //             code: response.url.substring(response.url.indexOf("code=")).replace("code=", ""),
      //             client_id:Config.ClientId,
      //          },
      //          (res) => {
      //             OkCb(res);

      //          }
      //       );
      //    }
      // });

      window.location.href=BASE_SITE_URL+
      '/connect/authorize?client_id='+Config.ClientId
      +
      '&response_type='+Config.ResponseType
      +
      '&scope='+Config.Scope
      +
      '&redirect_uri='+Config.RedirectUri
      ;

   });
 
}

export function ChangePassword(Model, OkCb) {
   http.Post(Url_changePass, Model, (res) => {
      OkCb(res);
   });
}
