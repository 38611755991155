import * as Constants from "../Utils/constants";
import * as app from "./AppService";
import store from "../Store/store";



async function LogOut() {
   await new Promise((resolve) => {
      localStorage.removeItem("user");
      resolve();
   });
   window.location.reload();
}



function handleResponse(type, response, message, OkCb) {
   app.HideSpinner();
   if (response.ok) {
      type !== "get" && type === "post"
         ? app.DisplayApiMsg("AddedSuccessfully", "success")
         : type === "put"
         ? app.DisplayApiMsg("UpdatedSuccessfully", "success")
         : type === "delete" && app.DisplayApiMsg(message !== "" ? message : "DeletedSuccessfully", "success");

      response.json().then((data) => {
         OkCb && OkCb(data);

      });
   } else {
      response.json().then((data) => {
         app.DisplayApiMsg(data?.error?.validationErrors || data?.error?.message, "error");
      });
   }
}

export function Post(url, model, OkCb) {
   app.ShowSpinner();
   const token =store.getState().Auth.access_token;

   let h={
      "Content-Type": "application/json",
      'Authorization': "Bearer " + token
   }
   let h1={
      "Content-Type": "application/json",

   }
   fetch(Constants.BASE_API_URL + url, {
      method: "post",
      credentials: "include",
      headers: url.includes('login')?h1:h,
      body: JSON.stringify(model),
   }).then((response) => {
      if (response.status === 204) {
         OkCb(true);
      } else if (response.status === 401) {
         app.DisplayApiMsg("sessionOver", "Warning");
         setTimeout(LogOut, 3000);
      } 
      else !url.includes("login")
         ? handleResponse("post", response, "", OkCb)
         : handleResponse("loginpost", response, "", OkCb);
   });
}

export function TokenPost(url, model, OkCb) {
   var prms = new URLSearchParams(model);
   fetch("" + url, {
      method: "post",
       credentials: 'include',
      headers: {
         "Content-Type": "application/x-www-form-urlencoded",
      },
      body: prms,
   })
   .then((response) => {
      if (response.ok) return response.json();
   })
   .then((data) => {
      if (OkCb) {
         OkCb(data);
      }
   });
}

export function Get(url, params, OkCb) {
   const token = store.getState().Auth.access_token;
   app.ShowSpinner();
   let parameters = Object.keys(params)
   .map(function (key) {
      return key + "=" + params[key];
   })
   .join("&");
   let Params = "";
   if (parameters && parameters.length > 0) {
      Params += "?" + parameters;
   }

   fetch(Constants.BASE_API_URL + url + Params, {
      method: "get",
      credentials: 'include',

      headers:  {   
         "Content-Type": "application/json",
      'Authorization': "Bearer " + token}   }).then((response) => {
      if (response.status === 401) {
         app.DisplayApiMsg("sessionOver", "Warning");
         setTimeout(LogOut, 3000);
      } else {
         handleResponse("get", response, "", OkCb);
      }
   });
}

export function Put(url, Id, model, OkCb) {
   const token = store.getState().Auth.access_token;

   fetch(`${Constants.BASE_API_URL + url}/${Id}`, {
      method: "put",
      headers:  {   
         "Content-Type": "application/json",
      'Authorization': "Bearer " + token},
      credentials: 'include',
      body: JSON.stringify(model),
   }).then((response) => {
      if (response.status === 204) {
         handleResponse("put", response, "", OkCb);
         OkCb(true);
      } else if (response.status === 401) {
         app.DisplayApiMsg("sessionOver", "Warning");
         setTimeout(LogOut, 3000);
      } else {
         handleResponse("put", response, "", OkCb);
         OkCb(false);
      }
   });
}

export function Delete(url, Id, OkCb, message = "") {
   const token = store.getState().Auth.access_token;

   fetch(`${Constants.BASE_API_URL + url}/${Id}`, {
      method: "delete",
      headers:  {   
         "Content-Type": "application/json",
      'Authorization': "Bearer " + token},
      credentials: 'include',   }).then((response) => {
      if (response.status === 204) {
         handleResponse("delete", response, message, OkCb);
         OkCb(true);
      } else if (response.status === 401) {
         app.DisplayApiMsg("sessionOver", "Warning");
         setTimeout(LogOut, 3000);
      } else {
         handleResponse("delete", response, message, OkCb);
         OkCb(false);
      }
   });
}

const http = {Delete, Get, Post, Put};
export default http;
