import PropTypes from "prop-types";
import React from "react";
import DefaultTable from "./DefaultTable";
import ApiTable from "./ApiTable";
const Table = (props) => {
   if (props.ApiPagenation) return <ApiTable {...props} />;
   else return <DefaultTable {...props} />;
};

export default Table;

Table.propTypes = {
   ApiPagenation: PropTypes.bool,
};
Table.defaultProps = {
   ApiPagenation: false,
};
