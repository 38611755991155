
export const Type = 'Object';

export const PostModel = {
    id: 0,
    supplierId: 0,
    timestamp: "",
    items: [
        {
            id: 0,
            purchaseId: 0,
            itemId: 0,
            itemTypeId: 0,
            unitPriceAmount: 0,
            quantity: 0,
            totalPriceAmount: 0
        }
    ],
    totalPriceAmount: 0,
    additionalAmount: 0,
}