import React, {useState} from "react";
import ReactPaginate from "react-paginate";
import {BsArrowLeftSquareFill, BsArrowRightSquareFill} from "react-icons/bs";

const Pagenation = (props) => {
   const [page, setPage] = useState(null);
   const DataLength = props.Data.length;
   function Items({currentItems}) {
      return (
         <>
            <div className="row">
               <div className="my-1 rounded p-2">
                  {currentItems?.map((item, index) => {
                     return <>{props.viewData(item, index)}</>;
                  })}
               </div>
            </div>
         </>
      );
   }

   const itemsPerPage = props.ShowNumber;
   const [itemOffset, setItemOffset] = useState(0);

   const endOffset = itemOffset + itemsPerPage;
   const currentItems = props.Data.slice(itemOffset, endOffset);
   const pageCount = Math.ceil(DataLength / itemsPerPage);

   const handlePageClick = (event) => {
      const newOffset = (event.selected * itemsPerPage) % DataLength;
      setItemOffset(newOffset);
   };
   return (
      <>
         <div style={{height: props.Height}}>
            <Items currentItems={currentItems} />
         </div>
         <ReactPaginate
            breakLabel="..."
            nextLabel={<BsArrowLeftSquareFill size={36} />}
            previousLabel={<BsArrowRightSquareFill size={36} />}
            previousClassName={itemOffset === 0 && "d-none"}
            nextClassName={endOffset === DataLength && "d-none"}
            onPageChange={handlePageClick}
            pageRangeDisplayed={props.PageRangeDisplayed}
            pageCount={pageCount}
            marginPagesDisplayed={props.MarginPagesDisplayed}
            containerClassName={`pagination gap-2 ${pageCount <= 1 && "d-none"}`}
            renderOnZeroPageCount={null}
            activeLinkClassName="active bg-primary text-white"
            pageLinkClassName="page-link"
            forcePage={page !== null ? page : 0}
         />
      </>
   );
};
export default Pagenation;
